.contactWrap {
  width: 50%;
  margin: 50px auto;
  text-align: center;
  min-height: 100%;
}

.contactWrap .contactForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.contactWrap .contactForm input,
textarea,
button {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid white;
  background-color: var(--dark);
  width: 100%;
  color: white;
}
.contactWrap .contactForm button {
  width: 50%;
  transition: all ease-in-out 0.3s;
}

input::placeholder {
  color: white;
}
textarea::placeholder {
  color: white;
}

button:hover {
  cursor: pointer;
  transform: scale(1.1);
}
