.successMessage {
  text-align: center;
  padding: 10px;
  color: white;
  background-color: var(--light-green);
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 100vh;
}

.successMessage button {
  padding: 20px;
  border-radius: 10px;
  font-weight: 600;
  border: none;
  background-color: var(--dark);
  color: white;
  transition: 0.15s;
}

.successMessage button:hover {
  filter: brightness(150%);
  transform: scale(1.03);
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .successMessage {
    font-size: 1.2rem;
  }
}
