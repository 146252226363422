.footer {
  background-color: var(--gray);
  color: white;
  padding: 30px 50px;
}
.footer .blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.footer .block {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer .block .inline {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer .block #logo {
  width: 100px;
}
.footer .block ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer #copyright {
  text-align: center;
  margin-top: 20px;
  padding: 20px 0 0px 0;
  border-top: 1px solid white;
}

.footer a {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.footer a:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.footer h3 {
  font-size: 2rem;
}

.footer button {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  color: var(--gray);
  background-color: var(--light-green);
}

.footer #email {
  width: 40px;
}

@media only screen and (max-width: 480px) {
  .footer .blocks {
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }
  .footer .block {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer .block .inline {
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
  }
  #socialMedia {
    justify-content: center;
  }
}
