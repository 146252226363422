h1 {
  text-align: center;
  margin: 50px 0;
  background: -webkit-linear-gradient(left, var(--light-green), var(--pink));

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
}

@media only screen and (max-width: 480px) {
  h1 {
    width: 80%;
    font-size: 2rem;
    margin: 50px auto;
  }
}
