.privacyPolicy {
  width: 50%;
  margin: 50px auto;
}
#headline {
  text-align: center;
  text-transform: uppercase;
  font-size: 3rem;
  margin: 50px 0;
}

.ppText {
  margin: 50px auto;
  text-align: center;
}
.privacyPolicy .mainText {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* width: 80%; */
  margin: auto;
}
.privacyPolicy .mainText .paragraph #paragraphHeadline {
  font-size: 1.5rem;
}

.privacyPolicy .mainText .paragraph #paragraphText {
  font-size: 1rem;
  margin-left: 20px;
}
.privacyPolicy .mainText.paragraph #paragraphText p {
  margin: 20px 0;
}

@media only screen and (max-width: 480px) {
  .privacyPolicy {
    width: 90%;
  }
}
