.heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0;
  text-align: center;
}

.faqText {
  width: 70%;
  margin: auto;
  text-align: center;
}

.faq {
  margin: 3rem 0;
}
.questionWrapper {
  max-width: 35rem;
  border-bottom: 1px solid rgb(138, 138, 138);
  margin: 0 auto;
  padding: 1rem;
  transition: 1s;
}

.question {
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: space-between;
}
.question svg {
  width: 1rem;
  height: 1.5rem;
}

.question svg.active {
  transform: rotate(180deg);
}

.question svg:hover {
  opacity: 0.8;
}

.container button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: unset;
}

.container button:focus {
  outline: none;
}

.answer {
  display: none;
}

.answer.active {
  display: block;
  text-align: left;
  padding-top: 1.5rem;
  font-weight: light;
  font-size: 0.8rem;
  line-height: 1.5;
  /* height: 0%; */
  animation: slidein 0.4s;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    opacity: 0.3;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
