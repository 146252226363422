/* GENERAL CSS FOR ENTIRE APP */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Montserrat" !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --light-green: #29d3d1;
  --pink: #ef32d9;
  --dark: #1f2938;
  --gray: #161d27;
}

.standard-btn {
  background-color: var(--dark);
  color: white;
  padding: 15px;
  border-radius: 50px;
  border: none;
  font-weight: 600;
  width: fit-content;
  margin: 20px auto;
  font-size: 1rem;
  transition: 0.5s;
}
.standard-btn:hover {
  background-color: var(--light-green);
  transform: scale(1.03);
  cursor: pointer;
}

.standard-btn.disable {
  background-color: var(--dark);
  color: gray;
}
.standard-btn.disable:hover {
  cursor: not-allowed;
}
