.navbar {
  background-color: var(--dark);
}

.logoBanner {
  background-image: linear-gradient(to right, var(--light-green), var(--pink));
  margin: auto;
}

#logo {
  width: 100px;
}
