#video {
  width: 60%;

  display: flex;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  margin: 50px auto;
}

@media only screen and (max-width: 480px) {
  #video {
    width: 90%;
  }
}
