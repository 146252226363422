.artistItem {
  transition: 0.4s;
  width: fit-content;
  margin: auto;
  flex: 1;
  height: auto;
  width: auto;
  position: relative;
}
.artistItem:hover {
  transform: scale(1.03);
  opacity: 0.95;
  cursor: pointer;
  #artistImage {
    border: 2px solid var(--light-green);

    box-shadow: 0px 0px 49px -12px rgba(2, 157, 61, 0.35);
  }
  #playBtn {
    transform: scale(1.02);
  }
}

.artistItem #artistImage {
  border-radius: 20px;
  border: 1px solid var(--light-green);
  width: 500px;
  height: 500px;

  object-fit: cover;
}

.artistItem .bottomInfo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  bottom: 15px;
  left: 10px;
  z-index: 9999;
}
.artistItem #playBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  padding-left: 12px;
  border: 1px solid var(--pink);
}

.artistItem #playBtn img {
  width: 21px;
}

.artistItem #artistName {
  font-weight: 600;
  text-transform: capitalize;
  color: white;
}

@media only screen and (max-width: 480px) {
  .artistItem {
    width: 90%;
  }

  .artistItem #artistImage {
    margin: auto;
    width: 100%;
  }
}
