.howItWorksWrap {
  width: 80%;
  margin: 100px auto;
}
.howItWorksWrap.images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.howItWorksWrap img {
  width: 20%;
  margin: 50px 0;
}

.howItWorksWrap .textBox {
  margin: 50px 0;
  width: 70%;
}
.howItWorksWrap .textBox h3 {
  color: var(--dark);
  color: var(--light-green);
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 10px;
  width: fit-content;
  text-align: left;
}
.howItWorksWrap .textBox p {
  margin-left: 100px;
  color: var(--dark);
  font-weight: 600;
}

.points {
  display: flex;
  position: relative;
}

.line {
  position: absolute;
  left: 24px;
  top: 50px;
  z-index: -999;

  content: "";
  height: 68%;
  width: 3px;

  background-color: rgb(0, 0, 0);
}
.heading {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  text-align: center;
}

.heading > div {
  padding: 15px 5px 5px 5px;
  background-color: var(--dark);
  color: white;
  font-weight: 600;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 1;
  transition: 0.3s;
}
.heading > div:hover {
  cursor: pointer;
  transform: scale(1.03);
  background-color: var(--light-green);
  color: var(--dark);
}

@media only screen and (max-width: 480px) {
  .images {
    display: flex;
    flex-direction: column;
  }
  .line {
    left: 28px;
  }
  .howItWorksWrap .images img {
    width: 100%;
    margin: 20px 0;
  }
  .howItWorksWrap {
    width: 100%;
  }
  .heading {
    gap: 10px;
    margin-left: 5px;
  }
  .howItWorksWrap .textBox {
    width: 100%;
  }
  .howItWorksWrap .textBox h3 {
    text-align: left;
    font-size: 1.8rem;
    width: 80%;
  }
  .howItWorksWrap .textBox p {
    text-align: left;
    font-weight: 500;
    margin-left: 80px;
  }
}
