.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.heading a {
  display: block;
  width: 400px;
  margin: auto;
}

.heading #price {
  padding: 10px;
  border: 4px solid;
  border-image: linear-gradient(var(--pink), var(--light-green)) 10;

  width: fit-content;
  text-align: center;
  margin: auto;
  color: white;
  font-weight: 600;
  background-color: var(--dark);
  transition: 0.3s;
}
.heading #price:hover {
  transform: scale(1.03);
  cursor: default;
}
#explainPrice {
  padding: 10px;
  border: 4px solid;
  border-image: linear-gradient(var(--pink), var(--light-green)) 10;

  width: fit-content;
  text-align: center;
  margin: auto;
  color: white;
  font-weight: 600;
  background-color: var(--dark);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  margin: auto;
}

.form > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--dark);
  font-weight: 500;
}

.form input,
textarea,
select {
  padding: 10px;
  border-radius: 10px;
  border: 3px solid var(--light-green);
  background-color: var(--dark);
  color: white;
  resize: none;
}
.form input::placeholder {
  color: rgba(255, 255, 255, 0.85);
}
.form textarea::placeholder {
  color: rgba(255, 255, 255, 0.85);
}

.form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--light-green) 50%),
    linear-gradient(135deg, var(--light-green) 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.form h3 {
  text-align: center;
  margin: 10px 0;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, var(--light-green), var(--pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.moreFields .fields {
  display: flex;
  flex-direction: row !important;
  gap: 5px;
  width: 100%;
}
.moreFields .fields > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.imageContainer {
  width: 100vw;
  height: 100vh;
  background: -webkit-linear-gradient(left, var(--light-green), var(--pink));
  display: flex;
  justify-content: center;
  align-items: center;
}
.acrylicPhotos {
  position: relative;
  height: 650px;
}
.acrylicPhotos > img:nth-child(1) {
  transform: translate(50%, 0%) rotate(0deg);
}
.acrylicPhotos > img:nth-child(2) {
  transform: translate(20%, 0%) rotate(-10deg);
}
.acrylicPhotos > img:nth-child(3) {
  transform: translate(80%, 5%) rotate(10deg);
  z-index: -1;
}
.acrylicPhotos > img:nth-child(4) {
  transform: translate(-10%, 10%) rotate(-20deg);
}
.acrylicPhotos > img:nth-child(5) {
  transform: translate(-40%, 30%) rotate(-30deg);
}
#acrylicPhoto {
  width: 40%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  margin: 50px auto;
  border: 3px solid var(--light-green);
  transition: opacity 0.3s ease-in-out;
  animation: show 500ms 300ms forwards;
  opacity: 0;
}
@keyframes show {
  100% {
    opacity: 1;
  }
}

.acrylicPhotos #acrylicPhoto {
  top: 0;
  left: 20%;
}
@media only screen and (max-width: 480px) {
  .form {
    width: 80%;
  }
  .moreFields .fields {
    flex-direction: column !important;
    gap: 10px;
  }
}
