.paymentBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.paymentBtns > button {
  margin: unset;
}
