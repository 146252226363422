.uploadFilesContainer {
  background-color: #f7fff7;
  width: 100%;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
}
.dragFileArea {
  border: 2px dashed;
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dragFileArea .uploadIcon {
  font-size: 50px;
}
.dragFileArea h3 {
  font-size: 26px;
  margin: 15px 0;
}
.dragFileArea label {
  font-size: 19px;
  display: block;
  width: fit-content !important;
}
.dragFileArea label input[type="file"] {
  display: none;
}
.browseFiles {
  text-align: center;
}
.dragFileArea label .browseFilesText {
  background: -webkit-linear-gradient(left, var(--light-green), var(--pink));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
}

.defaultFileInput {
  opacity: 0;
}
.cannotUploadMessage {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #bb0000;
  display: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.cannotUploadMessage span,
.uploadButtonIcon {
  padding-right: 10px;
}
.cannotUploadMessage span:last-child {
  padding-left: 20px;
  cursor: pointer;
}
.fileBlock {
  color: #f7fff7;
  background: var(--dark);
  transition: all 1s;
  width: 100%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}
.fileInfo {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.fileIcon {
  margin-right: 10px;
}
.fileName,
.fileSize {
  padding: 0 3px;
}
.removeFileIcon {
  cursor: pointer;
}
.progressBar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4bb543;
}
.uploadButton {
  font-family: "Montserrat";
  background-color: var(--dark);
  color: #f7fff7;
  display: flex;
  align-items: center;
  font-size: 18px;
  border: none;
  border-radius: 20px;
  margin: 10px;
  padding: 7.5px 50px;
  cursor: pointer;
}
@media only screen and (max-width: 480px) {
  .uploadFilesContainer {
    padding: 10px 20px;
  }
}
