.artistList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
  gap: 20px;
  width: 90%;
  padding: 10px;
  margin: 30px auto;
}

@media only screen and (max-width: 1450px) {
  .artistList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 480px) {
  .artistList {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}
